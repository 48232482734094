import * as React from 'react';
import { Link } from 'react-router-dom';

interface GuideMenuProps {
	basePath: string;
	folderPath: string;
}
export function GuideMenu(props: GuideMenuProps): React.ReactElement {
	return (
		<>
			<h3>利用ガイドメニュー</h3>
			<h4 className="el_guideMenuTitle el_guideMenuTitle__setting">登録・設定方法</h4>
			<ul className="bl_guideMenu">
				<li><Link to={props.folderPath + "/registration_setting/use_flow"}>はじめての方へ</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/preparation"}>事前準備</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/rakuten_system_setting"}>楽天システム設定</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/shop_setting"}>店舗設定</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/mail_setting"}>メール設定</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/from_review_privilege_up_to_campaign_register"}>レビュー特典〜キャンペーン登録</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/user_account_setting"}>ユーザーアカウント設定</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/password_setting"}>パスワード設定</Link></li>
				<li><Link to={props.folderPath + "/registration_setting/system_alert"}>システムアラート設定</Link></li>
			</ul>
			<h4 className="el_guideMenuTitle el_guideMenuTitle__operation">操作ガイド</h4>
			<ul className="bl_guideMenu">
				<li><Link to={props.folderPath + "/operation/top"}>TOP</Link></li>
				<li><Link to={props.folderPath + "/operation/review_privilege"}>レビュー特典</Link></li>
				<li><Link to={props.folderPath + "/operation/mail_setting"}>メール設定</Link></li>
				<li><Link to={props.folderPath + "/operation/various_setup"}>各種設定</Link></li>
				<li><Link to={props.folderPath + "/operation/csv_format"}>送り先CSV形式</Link></li>
			</ul>

			<ul className="bl_guideMenu bl_guideMenu__unique">
				<li><a className="el_btnWideBorderInv" href={props.basePath + "/faq"}>よくあるご質問</a></li>
			</ul>
		</>
	);
}
export default GuideMenu;