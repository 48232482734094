export function toArrayMap<T, K extends string | symbol>(elements: T[], keySelector: (element: T) => K): Record<K, T[] | undefined>;
export function toArrayMap<T, KL extends K, K extends string | symbol>(elements: T[], keySelector: (element: T) => K, keys: readonly KL[]): Record<KL, T[]>;
export function toArrayMap<T, KL extends K, K extends string | symbol>(elements: T[], keySelector: (element: T) => K, keys: readonly KL[] = []): Record<KL, T[] | undefined> {
	const map = new Map<K, T[]>(keys.map(key => [key, []]));
	for (const elem of elements) {
		const key = keySelector(elem);
		
		let array = map.get(key);
		if (!array) {
			array = [];
			map.set(key, array);
		}
		array.push(elem);
	}
	return Object.fromEntries(map.entries()) as any;
}
export default toArrayMap;
