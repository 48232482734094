import { capabilityIdMap, CapabilityType } from "../../const/capability-id-map";
import { entityIdMap, isEntityKind } from "../../const/entity-id-map";
import { isUUID } from "../../isUUID";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { Capability } from "./Capability";
import { Token } from "./Token";

export const userStateList = ['inviting', 'invitation_expired', 'pending', 'active', 'suspend', 'expired', 'none'] as const;
export type UserState = typeof userStateList[number];

export class User extends Entity {
	static typeId = entityIdMap['user.user'];

	name?: string;
	mail?: string;
	setPass?: boolean;
	capabilities?: Capability[];
	tokens?: Token[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { type: 'string' },
			mail: { type: 'string' },
			setPass: { type: 'boolean' },
			capabilities: { isArray: true, model: Capability },
			tokens: { isArray: true, model: Token },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			mail: this.mail,
			setPass: this.setPass,
			capabilities: this.capabilities,
			tokens: this.tokens,
		});
	}

	getEnableCaps(type: CapabilityType, entity?: string): Capability[] {
		if (!this.capabilities) {
			return [];
		}

		const isCapable = this.isCapableFuncEnable(type, entity);
		return this.capabilities.filter(isCapable);
	}

	hasEnableCaps(type: CapabilityType, entity?: string): boolean {
		if (!this.capabilities) {
			return false;
		}

		const isCapable = this.isCapableFuncEnable(type, entity);
		return this.capabilities.some(isCapable);
	}

	getCaps(type: CapabilityType, entity?: string): Capability[] {
		if (!this.capabilities) {
			return [];
		}

		const isCapable = this.isCapableFunc(type, entity);
		return this.capabilities.filter(isCapable);
	}

	hasCaps(type: CapabilityType, entity?: string): boolean {
		if (!this.capabilities) {
			return false;
		}

		const isCapable = this.isCapableFunc(type, entity);
		return this.capabilities.some(isCapable);
	}

	getEntityCaps(entity: string): Capability[] {
		if (!this.capabilities) {
			return [];
		}

		const isCapable = this.isCapableFuncEntity(entity);
		return this.capabilities.filter(isCapable);
	}

	hasEntityCaps(entity: string): boolean {
		if (!this.capabilities) {
			return false;
		}

		const isCapable = this.isCapableFuncEntity(entity);
		return this.capabilities.some(isCapable);
	}

	getEnableToken(type: CapabilityType, entity?: string): Token[] {
		if (!this.tokens) {
			return [];
		}
		
		const isToken = this.isTokenFuncEnable(type, entity);
		return this.tokens.filter(isToken);
	}

	hasEnableToken(type: CapabilityType, entity?: string): boolean {
		if (!this.tokens) {
			return false;
		}

		const isToken = this.isTokenFuncEnable(type, entity);
		return this.tokens.some(isToken);
	}

	getToken(type: CapabilityType, entity?: string): Token[] {
		if (!this.tokens) {
			return [];
		}
		
		const isToken = this.isTokenFunc(type, entity);
		return this.tokens.filter(isToken);
	}

	hasToken(type: CapabilityType, entity?: string): boolean {
		if (!this.tokens) {
			return false;
		}

		const isToken = this.isTokenFunc(type, entity);
		return this.tokens.some(isToken);
	}

	hasEnableResetPasswordToken(token: string): boolean {
		if (!this.tokens) {
			return false;
		}

		for (const tk of this.tokens) {
			if (tk.token == token) {
				return tk.isEnabled() && tk.isTypeResetPassword();
			}
		}
		return false;
	}

	getState(entity: string): UserState {
		const now = Date.now();

		const cap = this.getCaps('api_user_login', entity).shift();
		if (!cap) {
			/* ログイン権限がない場合、招待中か確認 */
			if (this.hasToken('api_user_login', entity)) {
				const token = this.getToken('api_user_login', entity).shift();
				return token?.isEnabled(now) ? 'inviting' : 'invitation_expired';
			}
			return 'none';
		}
		if (cap.disabled) return 'suspend';
		if (!cap.isEnabled(now)) return 'expired';

		if (this.hasCaps('pending', entity)) {
			/* 招待直後はpendingのこともある */
			return 'pending';
		}

		return 'active';
	}

	getExpire(entity: string): number | undefined {
		const state = this.getState(entity)
		if (['inviting', 'invitation_expired'].includes(state)) {
			const token = this.getToken('review_login', entity).shift() ?? this.getToken('yReview_login', entity).shift();
			return token?.expire;
		} else if (['active', 'expired'].includes(state)) {
			const cap = this.getCaps('review_login', entity).shift() ?? this.getCaps('yReview_login', entity).shift();
			return cap?.expire;
		}
		return undefined;
	}

	isAdmin(entity: string): boolean {
		return this.hasEnableCaps('review_master_read', entity) && this.hasEnableCaps('review_master_write', entity)
	}

	private isCapableFuncEnable(type: CapabilityType, entity?: string): (cap: Capability) => boolean {
		const capId = capabilityIdMap[type];
		const entId =
			isEntityKind(entity) ? entityIdMap[entity] :
			isUUID(entity) ? entity : '';
		const now = Date.now();

		return (cap) => {
			if (cap.id != capId) {
				return false;
			}
			if (entId && ![cap.entityId, cap.entityTypeId].includes(entId)) {
				return false;
			}
			if (!cap.isEnabled(now)) {
				return false;
			}
			return true;
		};
	}

	private isCapableFunc(type: CapabilityType, entity?: string): (cap: Capability) => boolean {
		const capId = capabilityIdMap[type];
		const entId =
			isEntityKind(entity) ? entityIdMap[entity] :
			isUUID(entity) ? entity : '';

		return (cap) => {
			if (cap.id != capId) {
				return false;
			}
			if (entId && ![cap.entityId, cap.entityTypeId].includes(entId)) {
				return false;
			}
			return true;
		};
	}

	private isCapableFuncEntity(entity: string): (cap: Capability) => boolean {
		const entId =
			isEntityKind(entity) ? entityIdMap[entity] :
			isUUID(entity) ? entity : '';

		return (cap) => {
			if (entId && ![cap.entityId, cap.entityTypeId].includes(entId)) {
				return false;
			}
			return true;
		};
	}

	private isTokenFuncEnable(type: CapabilityType, entity?: string): (token: Token) => boolean {
		const capId = capabilityIdMap[type];
		const entId =
			isEntityKind(entity) ? entityIdMap[entity] :
			isUUID(entity) ? entity : '';
		const now = Date.now();

		return (token) => {
			if (!token.isTypeActivate()) {
				return false;
			}
			if (token.capabilityId != capId) {
				return false;
			}
			if (entId && ![token.entityId, token.entityTypeId].includes(entId)) {
				return false;
			}
			if (!token.isEnabled(now)) {
				return false;
			}
			return true;
		};
	}

	private isTokenFunc(type: CapabilityType, entity?: string): (token: Token) => boolean {
		const capId = capabilityIdMap[type];
		const entId =
			isEntityKind(entity) ? entityIdMap[entity] :
			isUUID(entity) ? entity : '';

		return (token) => {
			if (!token.isTypeActivate()) {
				return false;
			}
			if (token.capabilityId != capId) {
				return false;
			}
			if (entId && ![token.entityId, token.entityTypeId].includes(entId)) {
				return false;
			}
			return true;
		};
	}
}
